<template>
	<b-modal id="share-details-modal" 
		hide-footer  
		hide-header
		no-close-on-backdrop 
		size="md" 
		modal-class="share-details-modal"
		body-bg-variant="dark"
	>


		<div> 
			<div class="message-border mb-3">
				<div class="message text-white text-center">
					<h6>You are about to share your data information to others. 
						Please select which data assets you would like to share:
					</h6>
				</div>
			</div>
			<h3>List of data assets:</h3>
			<form class="mt-4 text-white">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.SELFIE" :disabled="!allowShow.SELFIE" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Face
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.HOMENUMBER" :disabled="!allowShow.HOMENUMBER" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Home Number
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.DRIVERSLICENSE" :disabled="!allowShow.DRIVERSLICENSE" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Driver’s License
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.PASSPORT" :disabled="!allowShow.PASSPORT" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Passport
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.POA" :disabled="!allowShow.POA" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Proof of Address
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.EMPLOYMENT" :disabled="!allowShow.EMPLOYMENT" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Employment
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.HOMEASSET" :disabled="!allowShow.HOMEASSET" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Home Asset
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.VEHICLEASSET" :disabled="!allowShow.VEHICLEASSET" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Vehicle Asset
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.EDUCATION" :disabled="!allowShow.EDUCATION" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Education
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.GENDER" :disabled="!allowShow.GENDER" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Gender
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.OTHER" :disabled="!allowShow.OTHER" id="defaultCheck1" @change="show">
					<label class="form-check-label" for="defaultCheck1">
						Other
					</label>
				</div>
			</form>
			<div class="message-border mt-5">
				<div class="message text-white text-center">
					<h6>I hereby understand that I am sharing these information 
					via this link to get verification from IDChain community.</h6>
				</div>
			</div>
			<div class="d-flex flex-wrap justify-content-between mt-5" style="width:100%;">
				<button class="btn btn-main" style="width:40%;" @click="generateKey" v-if="showButton">Share</button>
				<button class="btn btn-cancel" style="width:40%;" @click="cancel">Cancel</button>
			</div>
		</div>
		<ChangePin/>
        <ConfirmLogout />
        <GenerateKey :sharingKey="key"/>
	</b-modal>
</template>

<script>
import ChangePin from '@/components/modal/ChangePin.vue'
import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
import GenerateKey from '@/components/modal/GenerateKey.vue'

export default {
	components: {
        ChangePin,
        ConfirmLogout,
        GenerateKey
    },
	data() {
		return {
			allowShow: {
				SELFIE: true,
                HOMENUMBER: false,
                DRIVERSLICENSE: false,
                PASSPORTNUMBER: false,
                POA: false,
                HOMEASSET: false,
                VEHICLEASSET: false,
                OTHER: false,
                GENDER: false, 
			},

			dataAssets: {
                SELFIE: false,
                HOMENUMBER: false,
                DRIVERSLICENSE: false,
                PASSPORTNUMBER: false,
                POA: false,
                HOMEASSET: false,
                VEHICLEASSET: false,
                OTHER: false,
                GENDER: false, 
            },
            key: "",
            showButton: false
		}
	},
	computed: {
      init() {
          return this.$store.state.init;
      },
    },
	methods: {
        formatAmount:  function (num) {
            if(isNaN(num)) {
            num = 0;
            }
            return parseFloat(num).toFixed(8)
        },
        formatDate: function(time){
            return new Date(time).toLocaleString();
        },
        show() {
            this.showButton = true
        },
		cancel() {
			this.$bvModal.hide('share-details-modal')
		},
		getPoids() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/poids',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    // this.poids = response.data
					console.log('.')
					Object.keys(this.allowShow).forEach((key) => {
						console.log(key)
						response.data.forEach((value) => {
							console.log(value)
							if(value.poidType == key) {
								this.allowShow[key] = true
							}
						})
					})

                })
                .catch((error) => {
                    
                });
        },
        generateKey() {
            var dataString = ""

            for (var key in this.dataAssets) {
                if (this.dataAssets.hasOwnProperty(key)) {
                    if(this.dataAssets[key] == true) {
                        dataString = dataString + key + ", "
                    }
                }
            }

            console.log(dataString.substring(0, dataString.length - 2))
            const params = {
                data: dataString.substring(0, dataString.length - 2)
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');

            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/chain/generatekey?' + data,
            };
            this.axios(options)
                .then((response) => {
                   this.key = window.location.href + 'data-assets/' + response.data.tokenId
                   console.log(this.key)
                   this.$bvModal.show('generate-key-modal'); 
                })
                .catch((error) => {
                    
                });
        },
    },
    mounted() {
		this.getPoids();
        let socketScript = document.createElement('script')
            socketScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.js')
            document.head.appendChild(socketScript)
    }
}
</script>

<style scoped>
	.message-border {
		width:90%;
		margin:0 auto;
		background:linear-gradient(to right, #0e649e, #fff700, #9e0e0e);
		border-radius:5px;
		padding:2px;
	}

	.message {
		background:#062035;
		padding:15px;
		border-radius:5px;
	}

	.btn-cancel {
		border:2px #9e0e0e solid;
		color:white;
		border-radius:5px;
	}

	h3 {
		color:#3297db;
	}
</style>